<template>
  <div class="actionBox">
    <div class="actionTitleAndMore">
      <div class="actionTitle">公告列表</div>
      <!-- <div class="more">更多 >></div> -->
    </div>
    <div class="row" v-for="x in state.list" :key="x">
      <div class="dot"></div>
      <div class="text">
        {{x.title || '--'}}
      </div>
      <div class="time">{{x.createTime.split(" ")[0]}}</div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { actionList, actionDetail} from "@/api";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: {},
  setup() {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      count: 0,
      list:false
    });

    onMounted(async () => {
      actionList({current:1,size:10}).then(res=>{
       state.list =  res.data.records
      })
    });
    return {
      state,
      //方法
    };
  },
};
</script>
<style lang="less" scoped>
.actionBox {
  margin-top: 10px;
  width: 340px;
  background: #ffffff;
  padding: 25px;
  box-sizing: border-box;
  .actionTitleAndMore {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(240, 240, 240, 1);
    .actionTitle {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #303133;
    }
    .more {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6085f1;
    }
  }
  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #b5b5b5;
    line-height: 40px;
    .dot {
      width: 6px;
      height: 6px;
      background: #dcdcdc;
      border-radius: 50%;
      margin-right: 5px;
      flex-shrink: 0;
    }
    .text {
      flex-grow: 1;
      color: rgba(96, 98, 102, 1);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .time {
      flex-shrink: 0;
    }
  }
}
</style>