<template>
  <div v-if="source && output">
    <div :id="source" style="display: none" v-html="source"></div>
    <div :id="output"></div>
  </div>
</template>

<script>
import Typing from "@/assets/js/typing";
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
export default {
  props: ["source", "output"],
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({});
    watch(
      () => props.source,
      (val) => {
        if (!val) return;
        _this.$nextTick(() => {
          var typing = new Typing({
            source: $("#" + props.source)[0],
            output: $("#" + props.output)[0],
            // delay: 300,
          });
          typing.start();
        });
      },
      { immediate: true }
    );
    onMounted(() => {});

    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>