<template>
  <div class="userBox">
    <div class="avatarAndNameAndCom">
      <img class="peopleAvatar" :src="info.avatar" alt="" />
      <div class="name">{{info.realName}}</div>
      <div class="com">
        <img src="@/assets/images/3273.png" alt="" />
        <span class="comName">{{info.deptName}}</span>
      </div>
    </div>
    <!-- <div class="row">
      <div class="descText">用户性别：</div>
      <div class="descValue">{{info.sexName}}</div>
    </div> -->
    <div class="row">
      <div class="descText">手机号码：</div>
      <div class="descValue">{{info.phone}}</div>
    </div>
    <div class="row">
      <div class="descText">电子邮箱：</div>
      <div class="descValue">{{info.email}}</div>
    </div>
    <div class="row">
      <div class="descText">部门：</div>
      <div class="descValue">{{info.tenantName}}</div>
    </div>
    <div class="allBtn">
      <div class="btn" @click="showInfo()">个人信息</div>
      <div class="btn" @click="showPwd()">修改密码</div>
      <div class="btn" @click="logOut()">退出登录</div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import Cookies from "js-cookie";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: {},
  props:['info'],
  setup(propms,ctx) {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      count: 0,
    });
    const showInfo = ()=>{
      ctx.emit("showInfo",true)
    };
    const showPwd = ()=>{
      ctx.emit("showPwd",true)
    };
    const logOut = () => {
      Cookies.remove("saber-refresh-token");
      Cookies.remove("nick_name");
      Cookies.remove("avatar");
      Cookies.remove("account");
      Cookies.remove("saber-access-token");
      setTimeout(() => {
        _this.$message({
          message: "退出登录成功！",
          type: "success",
        });
        _this.$router.push("./login");
      }, 200);
    };

    onMounted(async () => {});
    return {
      state,
      //方法
      logOut,
      showPwd,
      showInfo
    };
  },
};
</script>
<style lang="less" scoped>
.userBox {
  width: 340px;
  background: #ffffff;
  padding: 25px;
  box-sizing: border-box;
  .avatarAndNameAndCom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 40px 0;
    .peopleAvatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .name {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #303133;
      margin: 20px 0;
    }
    .com {
      display: flex;
      background: rgba(254, 143, 17, 0.1);
      padding: 4px;
      box-sizing: border-box;
      .comName {
        margin-left: 5px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9c611e;
      }
    }
  }
  .row {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 30px;
    .descText {
      width: 75px;
      color: #909399;
      text-align: right;
      margin-right: 10px;
    }
    .descValue {
      color: #303133;
    }
  }
  .allBtn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .btn {
      width: 90px;
      height: 30px;
      background: #f0f0f0;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6085f1;
      cursor: pointer;
    }
    .btn:nth-child(3) {
      color: rgba(236, 87, 78, 1);
    }
  }
}
</style>