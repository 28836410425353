<template>
  <div class="allServerBox" id="allServerBox">
    <div class="switchBtn">
      <el-switch
        v-model="state.value3"
        inline-prompt
        active-text="已绑定"
        inactive-text="全部"
      />
    </div>
    <template v-if="data">
      <div class="oneItem" v-for="(serve, idx) in data" :key="idx + 'dsafas'">
        <div class="itemTitle">
          {{ idx }}
        </div>
        <div class="row" :id="idx">
          <!-- v-if="state.value3 ? item.bindStatus == true : true" -->
          <template v-for="(item, i) in serve" :key="item + i + 'fdsgtd'">
            <div
              class="rowBox"
              :data-groups="JSON.stringify(item.group)"
              @click="openNew(item, $event)"
            >
              <img
                class="keyIcon"
                v-if="item.bindStatus"
                src="@/assets/images/key.png"
                alt=""
              />
              <img class="logo" :src="item.icon" alt="" />
              <div class="text">
                <span>
                  <Tying
                    :source="item.appName"
                    :output="item.appName + 'asd'"
                  />
                </span>
                <span class="smallSpan">{{ item.category }}</span>
              </div>
              <div
                v-if="item.bindStatus"
                @click.stop="unbindThis(item.id)"
                class="unbind"
              >
                解绑
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import Tying from "./tying.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  getJWTToken,
  loginAuth,
  getOtherInfoVO,
  updateBindStatus,
  unbind,
} from "@/api";
import _ from "lodash";
import Shuffle from "shufflejs";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
import { setTimeout } from "timers";
export default {
  props: ["data", "info", "token"],
  components: { Tying },
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      count: 0,
      value3: false,
      shuffleInstance: false,
      timer: false,
    });
    watch(
      () => state.value3,
      (val) => {
        _this.$nextTick(() => {
          for (const key in props.data) {
            val ? state[key].filter("" + val) : state[key].filter();
          }
        });
      }
    );
    watch(
      () => props.data,
      (val, oldVal) => {
        // console.log(val,'valvalvalval')
        if (JSON.stringify(val) == JSON.stringify(oldVal)) return;
        // console.log(val)
        _this.$nextTick(() => {
          // setTimeout(() => {
          for (const key in val) {
            if (state[key]) {
              state[key].resetItems();
            } else {
              state[key] = new Shuffle($("#" + key), {
                itemSelector: ".rowBox",
              });
            }
          }

          // val[key].forEach((e) => {
          //   e.group = ["" + e.bindStatus];
          //   state[key].resetItems();
          // });
          // }, 800);
        });

        // for (const key in val) {
        //   val[key].forEach((e) => {
        //     e.group = ["" + e.bindStatus];
        //     state[key].resetItems();
        //   });
        // }
        // _this.$nextTick(() => {
        //   setTimeout(() => {
        //     for (const key in props.data) {
        //       state[key] = new Shuffle($("#" + key), {
        //         itemSelector: ".rowBox",
        //       });
        //     }
        //   }, 500);
        // });
      },
      { deep: true,immediate:true }
    );
    const getHost = (url) => {
      if (!url) url = window.location.origin;
      var domain = url.split("/"); //以“/”进行分割

      if (domain[2]) {
        let arr = domain[2].split(".");
        domain = "." + arr[1] + "." + arr[2];
      } else {
        domain = ""; //如果url不正确就取空
      }
      return domain;
    };
    const unbindThis = (id) => {
      ElMessageBox.confirm("确认解绑该应用?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(({ value }) => {
          unbind({ id }).then((res) => {
            if (res.code == 200) {
              ctx.emit("getComAllList", true);
              ElMessage({
                type: "success",
                message: res.msg,
              });
              // setTimeout(() => {
              //   for (const key in props.data) {
              //     props.data[key].forEach((e) => {
              //       e.group = ["" + e.bindStatus];
              //       state[key].resetItems();
              //     });
              //   }
              // }, 800);
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消操作",
          });
        });
    };
    const sendInfo = (item) => {
      // console.log(item);
      getOtherInfoVO({ appId: item.id }).then((res) => {
        // if (res.code == 500) {
        //   updateBindStatus(item.id).then((res) => {
        //      return _this.$message({
        //       message: '用户身份失效，请重新绑定！！',
        //       type: "warning",
        //     });
        //   });
        // }
        let obj = Object.assign({}, res.data);
        window.open(
          res.data.certifiedAddress +
            `?username=${obj.appAccount}&password=${obj.password}&userId=${props.info.id}`,
          "_blank"
        );
      });
    };
    const openNew = (item, event) => {
      if(state.timer) clearInterval(state.timer);
      if (item.protocol == "jwt") {
        if (item.bindStatus) {
          // console.log(1)
          getJWTToken({ appId: item.id }).then((res) => {
            let Authorization = res.data;
            if (item.certifiedAddress.indexOf(".nprc.net")!= -1) {
              if (item.certifiedAddress.indexOf(getHost()) != -1) {
                _this.setOtherCookie(item.header, Authorization, 7);
                window.open(item.certifiedAddress, "_blank");
                return;
              }
            }
            var popup = window.open(item.certifiedAddress, "_blank");
            if (popup) {
              // console.log(2)
              let a = 0;
              state.timer = setInterval(() => {
                // console.log(a)
                if (a >= 30000) {
                  clearInterval(state.timer);
                }
                popup.postMessage(
                  JSON.stringify({ Authorization }),
                  item.certifiedAddress
                );
                a += 500;
              }, 500);
              // setTimeout(function () {
              //   popup.postMessage(
              //     JSON.stringify({ Authorization }),
              //     item.certifiedAddress
              //   );
              // }, 500);
            }
          });
        } else {
          if (item.loginUrl.indexOf(".nprc.net") != -1) {
            if (item.loginUrl.indexOf(getHost()) != -1) {
              _this.setOtherCookie("userId", props.info.id, 7);
              _this.setOtherCookie("appId", item.id, 7);
              window.open(item.loginUrl, "_blank");
              return;
            }
          }
          var popup = window.open(item.loginUrl, "_blank");
          if (popup) {
            let a = 0;
            state.timer = setInterval(() => {
              if (a >= 30000) {
                clearInterval(state.timer);
              }
              popup.postMessage(
                JSON.stringify({ id: props.info.id, appid: item.id }),
                item.loginUrl
              );
              a += 500;
            }, 500);
            // console.log(1221121221)
            // setTimeout(function () {
            //   popup.postMessage(
            //     JSON.stringify({ id: props.info.id, appid: item.id }),
            //     item.loginUrl
            //   );
            // }, 500);
          }
        }
      }

      if (item.protocol == "other") {
        if (item.bindStatus) {
          sendInfo(item);
        } else {
          ctx.emit("showAppLogin", item);
        }
      }

      // var popup = window.open(item.loginUrl);
    };

    onMounted(() => {
      setTimeout(() => {
        _this.$nextTick(() => {
          state.value3 = true;
        });
      }, 500);
      // _this.$nextTick(() => {
      //   setTimeout(() => {
      //     for (const key in props.data) {
      //       state[key] = new Shuffle($("#" + key), {
      //         itemSelector: ".rowBox",
      //       });
      //     }
      //   }, 500);
      // });
    });
    return {
      state,
      openNew,
      //方法
      sendInfo,
      unbindThis,
    };
  },
};
</script>
<style lang="less" scoped>
.allServerBox {
  width: 960px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
  background: #ffffff;
  .oneItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .itemTitle {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #303133;
      margin-bottom: 20px;
    }
    .row {
      width: 100%;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .rowBox {
        width: 290px;
        border: 1px solid #efefef;
        height: 120px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding: 30px 20px;
        box-sizing: border-box;
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;
        .logo {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        .text {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #282828;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          .smallSpan {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #b5b5b5;
            line-height: 20px;
            margin-top: 13px;
          }
        }
        .keyIcon {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 20px;
          object-fit: contain;
        }
        .unbind {
          position: absolute;
          right: 10px;
          bottom: 10px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: gray;
          cursor: pointer;
        }
        .unbind:hover {
          color: #6085f1;
        }
      }
      .rowBox:nth-last-child {
        margin: 0;
      }
      .rowBox:hover {
        box-shadow: 0px 0px 27px 3px rgba(181, 181, 181, 0.3);
      }
    }
  }
  .oneItem:nth-child(1) {
    margin: 0;
  }
}
.switchBtn {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>