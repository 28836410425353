<template>
  <el-scrollbar style="height: 100vh">
    <div class="allContent">
      <div class="leftInfo">
        <Usernfo
          v-if="state.leftUserinfo"
          :info="state.leftUserinfo"
          @showInfo="showInfo"
          @showPwd="showPwd"
        />
        <ActionList />
      </div>
      <div class="rightInfo">
        <div class="rightTopImg">
          <el-carousel height="220px" :interval="5000" arrow="always">
            <el-carousel-item v-for="item in state.imgs" :key="item">
              <img :src="item" alt="" srcset="" />
            </el-carousel-item>
          </el-carousel>
        </div>

        <AllServer
          ref="BarChartDateRange"
          v-if="state.allServerData"
          @showAppLogin="showAppLogin"
          @getComAllList="getComAllList"
          :info="state.userinfo"
          :data="state.allServerData"
          :token="state.token"
        />
      </div>
      <el-dialog
        :close-on-click-modal="state.userinfo.passwordStatus ? false : true"
        :title="state.userinfo.passwordStatus ? '修改初始密码' : '修改密码'"
        v-model="state.dialogFormPwd"
        width="30%"
      >
        <el-form
          ref="dataForm"
          :rules="state.rules"
          :model="state.temp"
          label-position="right"
          label-width="100px"
        >
          <el-form-item label="原密码" prop="oldPassword">
            <el-input
              show-password
              type="password"
              v-model="state.temp.oldPassword"
              placeholder="请填写原密码"
            />
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              show-password
              type="password"
              v-model="state.temp.newPassword"
              placeholder="请填写新密码"
            />
          </el-form-item>
          <el-form-item label="确认密码" prop="newPassword1">
            <el-input
              show-password
              type="password"
              v-model="state.temp.newPassword1"
              placeholder="请确认新密码"
            />
          </el-form-item>
        </el-form>
        <div
          v-if="state.userinfo.passwordStatus"
          style="color: red; float: right"
        >
          *初次登陆必须修改原始密码
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="updatePwd">提交</el-button>
            <el-button
              v-if="!state.userinfo.passwordStatus"
              @click="state.dialogFormPwd = false"
              >取消</el-button
            >
          </span>
        </template>
      </el-dialog>
      <el-dialog title="个人信息" v-model="state.dialogFormInfo" width="30%">
        <el-form
          ref="dataForm"
          :rules="state.infoRules"
          :model="state.userinfo"
          label-position="right"
          label-width="100px"
        >
          <el-form-item label="一寸照片：" prop="avatar">
            <div class="w flex items-center">
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="upFile"
                list-type="picture"
                :auto-upload="false"
              >
                <img
                  v-if="state.userinfo.avatar"
                  :src="state.userinfo.avatar"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="姓名：" prop="realName">
            <el-input
              v-model="state.userinfo.realName"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户名：" prop="name">
            <el-input
              v-model="state.userinfo.name"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input
              v-model="state.userinfo.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" prop="email">
            <el-input
              v-model="state.userinfo.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="updateInfo">提交</el-button>
            <el-button @click="state.dialogFormInfo = false">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        title="认证授权(认证第三方信息)"
        v-model="state.dialogFormApp"
        width="30%"
      >
        <el-form
          ref="dataForm"
          :rules="state.appRules"
          :model="state.appTemp"
          label-position="right"
          label-width="80px"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              ref="username"
              v-model="state.appTemp.username"
              placeholder="请输入用户名"
              name="username"
              type="text"
              tabindex="1"
            />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              ref="password"
              v-model="state.appTemp.password"
              placeholder="请输入密码"
              name="password"
              show-password
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="login()">认证</el-button>
            <el-button @click="state.dialogFormApp = false">取消</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>

<script>
import * as qiniu from "qiniu-js";
import { Usernfo, ActionList, AllServer } from "@/components";
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import {
  getComList,
  getUserInfo,
  emitInfo,
  emitPwd,
  refreshToken,
  bindAppByUser,
  getQNToken,
  uploadImg,
} from "@/api";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
import md5 from "js-md5";
import { setInterval } from "timers";
export default {
  components: {
    Usernfo,
    ActionList,
    AllServer,
  },
  setup() {
    const _this = getCurrentInstance().proxy;
    const BarChartDateRange = ref(null);
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      ws: false,
      count: 0,
      certifiedAddress: false,
      allServerData: false,
      dialogFormPwd: false,
      dialogFormInfo: false,
      imgs: [
        require("../assets/images/img1.png"),
        require("../assets/images/img2.png"),
      ],
      temp: { newPassword1: "", newPassword: "", oldPassword: "" },
      rules: {
        oldPassword: [
          { required: true, message: "请填写旧密码", trigger: "change" },
        ],
        newPassword: [
          { required: true, message: "请填写新密码", trigger: "change" },
        ],
        newPassword1: [
          { required: true, message: "请确认新密码", trigger: "change" },
        ],
      },
      userinfo: { avatar: "", realName: "", name: "", email: "", phone: "" },
      leftUserinfo: "",
      infoRules: {
        avatar: [{ required: true, message: "请上传头像", trigger: "change" }],
        realName: [
          { required: true, message: "请填写姓名", trigger: "change" },
        ],
        name: [{ required: true, message: "请填写用户名", trigger: "change" }],
        email: [{ required: true, message: "请填写邮箱", trigger: "change" }],
        phone: [{ required: true, message: "请填写手机号", trigger: "change" }],
      },
      dialogFormApp: false,
      appTemp: { username: "", password: "" },
      appRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
    });
    const showAppLogin = (item) => {
      // console.log(item);
      state.dialogFormApp = true;
      state.certifiedAddress = item;
    };
    const showInfo = () => {
      state.dialogFormInfo = true;
    };
    const showPwd = () => {
      $(".el-dialog__headerbtn").show();
      state.dialogFormPwd = true;
    };
    const updateInfo = () => {
      emitInfo(state.userinfo).then((res) => {
        if (res.code != 200) return;
        _this.$message({
          message: res.msg,
          type: "success",
        });
        getManInfo();
        state.dialogFormInfo = false;
      });
    };
    const updatePwd = () => {
      let pwd = Object.assign({}, state.temp);
      pwd.oldPassword = md5(pwd.oldPassword);
      pwd.newPassword = md5(pwd.newPassword);
      pwd.newPassword1 = md5(pwd.newPassword1);
      emitPwd(pwd).then((res) => {
        if (res.code != 200) {
          _this.$message({
            message: res.msg,
            type: "warning",
          });
          return;
        }
        _this.$message({
          message: res.msg,
          type: "success",
        });
        state.dialogFormPwd = false;
      });
    };
    const login = () => {
      let obj = Object.assign({}, state.appTemp);
      // console.log(state.certifiedAddress)
      // console.log(state.certifiedAddress.appName == "社保卡业务知识库系统")
      obj.password =
        state.certifiedAddress.appName == "社保卡业务知识库系统"
          ? window.btoa(obj.password)
          : md5(obj.password);
      // obj.password =md5(obj.password);
      if (state.certifiedAddress.appName == "社保卡业务知识库系统") {
        // _this.$axios
        //   .get(state.certifiedAddress.certifiedAddress, { params: obj })
        //   .then((res) => {
        //     console.log(res)
        // state.token = res.token;
        // bindAppByUser({
        //   userId: state.userinfo.id,
        //   password: obj.password,
        //   appAccount: obj.username,
        //   appId: state.certifiedAddress.id,
        // }).then((res) => {
        //   if (res.data == true) {
        //     _this.$message({
        //       message: res.msg,
        //       type: "success",
        //     });
        //     state.dialogFormApp = false;
        //     _this.$refs.BarChartDateRange.sendInfo(state.certifiedAddress);
        //   } else {
        //     this.$message({
        //       message: res.msg,
        //       type: "warning",
        //     });
        //   }
        // });
        // });
        state.dialogFormApp = false;
        window.open(
          state.certifiedAddress.certifiedAddress +
            `?username=${obj.username}&password=${obj.password}&userId=${state.userinfo.id}`
        );
      } else {
        $.ajax({
          type: "GET", //请求的方式，例如GET或POST
          url: state.certifiedAddress.certifiedAddress, //请求的URL地址
          data: obj, // 这次请求要携带的数据
          success: function (res) {
            try {
              state.token = res.token;
              bindAppByUser({
                userId: state.userinfo.id,
                password: obj.password,
                appAccount: obj.username,
                appId: state.certifiedAddress.id,
              }).then((res) => {
                if (res.data == true) {
                  state.dialogFormApp = false;
                  _this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  _this.$refs.BarChartDateRange.sendInfo(
                    state.certifiedAddress
                  );
                } else {
                  _this.$message({
                    message: res.msg,
                    type: "warning",
                  });
                }
              });
            } catch (err) {
              console.log(err);
              console.log(err.response);
            }
          }, // 请求成功之后的回调函数
        });
      }
    };
    // 随机字符串
    const randomString = (len) => {
      len = len || 32;
      var $chars =
        "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      var maxPos = $chars.length;
      var pwd = "";
      for (var i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    };

    // 文件大小
    const getfilesize = (size) => {
      if (!size) return "0K";

      var num = 1024.0; //byte

      if (size < num) return size + "B";
      if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "K"; //kb
      if (size < Math.pow(num, 3))
        return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
      if (size < Math.pow(num, 4))
        return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
      return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
    };
    const upFile = (e) => {
      const file = e.raw;
      
      // getQNToken().then((res) => {
      //   const token = res.data;
      //   // return
      //   var config = {
      //     useCdnDomain: true,
      //   };
      //   var putExtra = {
      //     fname: "",
      //     params: {},
      //   };
      var size = getfilesize(file.size);
      var str = randomString(10);
      var time = new Date().getTime();
      var types = file.name.split(".");
      var type = types[types.length - 1];
      var key = time + str + "." + type;
      var pettern = /^image/;
      if (!pettern.test(file.type)) {
        return _this.$message({
          message: "格式不正确,请上传正确的图片格式！",
          type: "warning",
        });
      }
      let form = new FormData();
      console.log(file);
      form.append("file", file);
      console.log(form)
      uploadImg(form).then((res) => {
        // console.log(res)
        // res.data.url = res.data.filePath;
        // _this.temp.fileInfoVO = res.data;
        // console.log()
        state.userinfo.avatar = _this.$axios.defaults.baseURL + res.data.url;
        if (res.code == 200) {
          _this.$message({
            message: "上传成功！",
            type: "success",
          });
        }
      });
      return;
      var observable = qiniu.upload(file, key, token, putExtra, config);
      var observer = {
        next(res) {
          console.log("成功", res);
        },
        error(err) {
          console.log("失败", err);
        },
        complete(res) {
          var url = _this.$image + res.key;
          console.log(url);
          var formats = file.name.split(".");
          var formatName = "";
          formats.forEach((e, i) => {
            if (i != formats.length - 1) formatName += e;
          });
          state.userinfo.avatar = url;
          // var data = {
          //   name: formatName,
          //   format: formats[formats.length - 1],
          //   url: url,
          //   size: size,
          //   upload: true,
          // };

          // _this.temp.bizFile.push({
          //   fileName: data.url,
          //   filePath: data.url,
          //   previewPath: data.url,
          //   type: 1,
          // });
          _this.$message({
            message: "上传成功！",
            type: "success",
          });
          // });
          console.log("通用", res);
        },
      };
      // eslint-disable-next-line no-unused-vars
      // var subscription = observable.subscribe(observer); // 上传开始
      // subscription.unsubscribe() // 上传取消
      // });
    };
    onActivated(async () => {
      //   await getAssece();
      //   await getManInfo();
      //   await getComAllList();
      //   let http =
      //     process.env.NODE_ENV === "production"
      //       ? "sso.nprc.net/api"
      //       : "192.168.110.54:8880/api";
      //  state.ws = new WebSocket(
      //     "ws://" + http + "/websocket/" + +state.userinfo.id
      //   );
      //   state.ws.onopen = () => {
      //     console.log("websocket已链接.");
      //   };
      //   state.ws.onmessage = (e) => {
      //     state.allServerData = e;
      //   };
    });
    const getComAllList = async () => {
      await getComList().then((res) => {
        if (res.code == 200) {
          for (const key in res.data) {
            res.data[key].forEach((e) => {
              e.group = ["" + e.bindStatus];
            });
          }
          state.allServerData = res.data;
        }
      });
    };
    const getAssece = async () => {
      let time = JSON.parse(sessionStorage.getItem("access_token"));
      if (!time) return;
      let refresh_token = time.data;
      time = Number(time.dataTime);
      if (!datedifference(time)) {
        refreshToken({
          tenantId: "000000",
          refresh_token,
          grant_type: "refresh_token",
          scope: "all",
        }).then((res) => {
          _this.setCookie("saber-access-token", res.access_token, 7);
          _this.setCookie("saber-refresh-token", res.refresh_token, 7);
          sessionStorage.setItem(
            "saber-access-token",
            JSON.stringify({
              data: res.refresh_token,
              dataTime: new Date().getTime(),
            })
          );
        });
      }
      setInterval(() => {
        time = JSON.parse(sessionStorage.getItem("access_token"));
        time = Number(time.dataTime);
        if (!datedifference(time)) {
          refreshToken({
            tenantId: "000000",
            refresh_token,
            grant_type: "refresh_token",
            scope: "all",
          }).then((res) => {
            // console.log(res)
            _this.setCookie("saber-access-token", res.access_token, 7);
            _this.setCookie("saber-refresh-token", res.refresh_token, 7);
            sessionStorage.setItem(
              "access_token",
              JSON.stringify({
                data: res.refresh_token,
                dataTime: new Date().getTime(),
              })
            );
          });
        }
      }, 10000);
    };
    const datedifference = (time) => {
      //计算工作日方法：遍历这两个日期区间的每一个日期，获取他的getDay()
      //分别获取first和last的毫秒数(时间戳)

      let first = new Date().getTime();
      var count = true;
      let cha = first - time;
      if (cha >= 5 * 60 * 1000) {
        count = false;
      }
      return count;
    };
    const getManInfo = async () => {
      await getUserInfo().then((res) => {
        state.userinfo = res.data;
        state.dialogFormPwd = res.data.passwordStatus;
        if (res.data.passwordStatus) {
          $(".el-dialog__headerbtn").hide();
        }
        state.leftUserinfo = Object.assign({}, res.data);
      });
    };
    onMounted(async () => {
      await getAssece();
      await getManInfo();
      await getComAllList();

      let http =
        process.env.NODE_ENV === "production"
          ? "wss://" + window.location.host + "/api/websocket/"
          : "ws://" + _this.$ip + "/api/websocket/";
      state.ws = new WebSocket(http + state.userinfo.id);
      state.ws.onopen = () => {
        console.log("websocket已链接.");
      };
      state.ws.onmessage = (e) => {
        // console.log(JSON.parse(e.data))
        state.allServerData = JSON.parse(e.data);
        for (const key in state.allServerData) {
          state.allServerData[key].forEach((e) => {
            e.group = ["" + e.bindStatus];
          });
        }
        // console.log(state.allServerData)
      };
    });

    return {
      state,
      showInfo,
      showPwd,
      updateInfo,
      updatePwd,
      showAppLogin,
      login,
      BarChartDateRange,
      upFile,
      getComAllList,
    };
  },
};
</script>

<style lang="less"  scoped>
.allContent {
  width: 1320px;
  // padding: 70px 300px 20px 300px;
  // box-sizing: border-box;
  // display: flex;
  // margin: 0 auto;
  // height: 100%;
  // display: flex;
  // justify-content: space-between;
  padding: 70px 0px 20px 0px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  // position: absolute;
  // left: 50%;
  // top: 0;
  // transform: translate(-50%, 0px);
  .leftInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin-right: 20px;
  }
  .rightInfo {
    width: 960px;
    .rightTopImg {
      width: 960px;
      height: 260px;
      overflow: hidden;
      background: #ffffff;
      padding: 20px;
      box-sizing: border-box;
      img {
        width: 100%;
        object-fit: contain;
        box-sizing: border-box;
      }
    }
  }
}
.avatar-uploader {
  border: 1px dashed gray;
  border-radius: 5px;
  // width: 100px;
  // height: 100px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: auto;
  display: block;
}
</style>